



































import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {DappConnection} from '@/model/resource/DappConnection'
import {InputDappConnectionSchema} from '@/schema/resource/DappConnection/InputDappConnectionSchema'

@Component
export default class PersistDappConnectionView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputDappConnectionSchema()
  dappConnection = new DappConnection()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.dappConnection.getDappConnection(id)
    }

    this.$await.done('getDappConnection')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.dappConnection.persistDappConnection()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/dapp-connection/list')
  }
}
